<template>
  <section>
    <b-row
      class="text-center"
      align-v="center"
    >
      <b-col />
      <div id="background_yellow">
        <b-button
          id="click"
          class="main-navigation-button"
          variant="success"
          style="min-width: 300px; min-heigth: 150px; font-size: 35px; padding: 20px 38px;"
          @click="sortear"
        >
          Sortear agora!
        </b-button>
        <div id="background_image" />
      </div>
      <b-col />
    </b-row>
    <br>
    <div id="background_blue">
      <p id="text_blue">
        {{ sortRandom.email }}
      </p>
    </div>
    <div id="background_green">
      <img
        id="congratulations"
        src="../../../assets/images/raffle/textura_ganhador_sorteio.png"
        alt="Congratulations image"
        width="90%"
        height="90%"
      >
      <p id="text_green">
        O sorteado foi:
      </p>
      <div id="divSort">
        <p id="textSort">
          {{ sort.email }}
        </p>
      </div>
      <p id="sortDate">
        Data do sorteio: <br>{{ new Date().toLocaleDateString('pt-BR') }}
      </p>
    </div>
  </section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import raffleServices from './raffleServices'

export default {
  components: {
  },
  data() {
    return {
      exemple: '',
      sort: '',
      sortRandom: '',
      idDiv: '',
      sortCount: 0,
      list: ['teste@sorteio.com', 'ty2@sorteio.com', 'uip@sorteio.com']
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.getEmail()
  },
  methods: {
    sortear() {
      this.idDiv = 'background_yellow'
      document.getElementById(this.idDiv).style.display = 'none'
      this.idDiv = 'background_blue'
      document.getElementById(this.idDiv).style.display = 'block'
      const intervalCount = setInterval(() => {
        const min = Math.ceil(0)
        const max = Math.floor(this.list.length)
        this.sortRandom = this.list[Math.floor(Math.random() * (max - min)) + min]
        console.log(this.sortRandom, this.sortCount)
        this.sortCount += 1
        if (this.sortCount === 5) {
          this.sort = this.list[Math.floor(Math.random() * (max - min)) + min]
          this.idDiv = 'background_blue'
          document.getElementById(this.idDiv).style.display = 'none'
          this.idDiv = 'background_green'
          document.getElementById(this.idDiv).style.display = 'block'
          this.create()
          clearInterval(intervalCount)
        }
      }, 3000)
    },
    async create() {
      // Call to create request
      const response = await raffleServices().riffleCreate({ user_id: this.sort.user_id })

      if (response.data.status === 400) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: response.data.message,
            text: ` ERROR: ${response.data.errors[0][0]} #${response.data.errors[0][1]}`,
            variant: 'danger',
          }
        })
      } else if (response.data.status === 200) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'InfoIcon',
            variant: 'success',
          }
        })
      }
    },
    async getEmail() {
      // Call to create request
      const response = await raffleServices().getList()
      this.list = response.data.response.emails
    }
  },
}
</script>
<style scoped>
#background_yellow {
  background-color: #f9ffcb;
  width: 100%;
  position: relative;
  display: block;
  }
#background_image {
  background-image: url('../../../assets/images/raffle/trofeu.png');
  background-size: 100%;
  width: 100%;
  height:1000px;
  background-repeat: no-repeat;
  margin-top: 170px;
  }
#background_blue {
  background-color: #64c5e4;
  background-size: 100%;
  width: 100%;
  height:500px;
  display: none;
  }
#background_green {
  background-color: #18b16e;
  background-size: 100%;
  width: 100%;
  height:500px;
  display: none;
  }
#click {
  margin: 0;
  position: absolute;
  margin-top: 60px;
  left: 50%;
  margin-left: -4.5em;
  }
#card {
  padding-top: 370px;
  }
#text_blue {
  animation: myAnim 2s ease 0s infinite normal forwards;
  padding-top: 500px;
  text-align: center;
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: bold;
  color: white;
  }
#text_green {
  font-size: 25px;
  font-weight: 600;
  color: white;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 140px;
  }
#textSort {
  font-size: 40px;
  width: max-content;
  margin: 60px 30px;
  font-weight: bold;
  color: white;
  text-align: center;
  }
#divSort {
  position: absolute;
  height: 90px;
    top: 150px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  width: max-content;
  border: 4px solid yellow;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
    }
#sortDate {
  font-size: 17px;
  font-weight: 600;
  color: white;
  text-align: center;
  padding-top: 80px;
  }
@keyframes myAnim {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-850px);
  }
}
#congratulations {
  position: absolute;
  animation: myAnime2 2s ease 0s infinite normal forwards;
}

@keyframes myAnime2 {
  0% {
    animation-timing-function: ease-out;
    transform: scale(1);
    transform-origin: center center;
  }

  10% {
    animation-timing-function: ease-in;
    transform: scale(0.91);
  }

  17% {
    animation-timing-function: ease-out;
    transform: scale(0.98);
  }

  33% {
    animation-timing-function: ease-in;
    transform: scale(0.87);
  }

  45% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}
</style>
