import axios from '@axios'

export default function raffleServices() {
  const riffleCreate = async (queryParams) => {
    const api = await axios.post('/riffle/create', queryParams)
    return api
  }

  const getList = async (queryParams) => {
    const api = await axios.get('/riffle/combo/emails', {
      params: queryParams,
    })
    console.log(api)
    return api
  }

  return {
    riffleCreate,
    getList,
  }
}
